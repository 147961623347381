import * as Xstate from "@xstate/react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
  timeFormatter,
} from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { getAllTimes } from "../../services/AppService";
import { FormSection } from "../../support/FormSection";
import { ITime } from "../../types/types";
import EditTime from "./EditTime";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { navigateToContact } from "../contacts/utils/navigateToContacts";
import { ExcelExport } from "../../utils/ExportData";
import { useGetBilledHoursQuery } from "../../slices/UserSlice";

export const TimesListView: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appReducer);

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { times } = useAppSelector((state) => state.appReducer);

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentTime, setCurrentTime] = useState<ITime | null>(null);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };
  const { data: billableHours } = useGetBilledHoursQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });

  useEffect(() => {
    dispatch(getAllTimes({ id: getLinkedFirm()?.orgId, userId: user.id }));
  }, []);

  return (
    <>
      <div className={"flex justify-start gap-6"}>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Billable amount</div>
          <div className={"text-2xl font-bold"}>
            ${billableHours?.billedAmount}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Billed hours</div>
          <div className={"text-2xl font-bold"}>
            {billableHours && timeFormatter(billableHours?.billedHours)}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Unbilled hours</div>
          <div className={"text-2xl font-bold"}>
            {billableHours && timeFormatter(billableHours?.unBilledHours)}
          </div>
        </div>
      </div>
      <FormSection name={""}>
        <div>
          <table className={"w-full"}>
            <thead className={"border-b-[1px] border-gray-300"}>
              <tr className={"py-[16px] pl-[16px] pr-[32px] last:pr-[16px]"}>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Contact
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Work Type
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Total Time
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Date
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Assignee
                </th>
                <th
                  className={
                    "w-[100px] py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Notes
                </th>
                <th
                  className={
                    "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                  }>
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              {times &&
                times.map((time: any) => (
                  <tr
                    key={time?.id}
                    className={
                      "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                    }>
                    <td className={"px-[16px] py-[8px] align-top"}>
                      <div className={"flex items-center"}>
                        <div className={"max-w-[300px] whitespace-normal"}>
                          <Link
                            to={`/work/${time?.work?.id}`}
                            className={
                              "link-primary text-sm font-medium hover:underline"
                            }>
                            {time.work?.name}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top"}>
                      <div className={"flex items-center"}>
                        {time.contactId ? (
                          <div className={"flex items-center text-[14px]"}>
                            <AvatarPlaceholder
                              size={"extra-small"}
                              label={getInitials(
                                time?.contact?.firstName || "",
                                time?.contact?.lastName || "",
                              )}
                            />
                            <div className={"pl-2 text-[14px] normal-case"}>
                              <Link
                                to={
                                  time.contact
                                    ? navigateToContact({
                                        type: "contact",
                                        customerId:
                                          time?.work?.customerId || "",
                                      })
                                    : ""
                                }
                                className={
                                  "link-primary text-sm font-medium hover:underline"
                                }>
                                {time.contact
                                  ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                                  : "Contact name"}
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className={"flex items-center text-[14px]"}>
                            <AvatarPlaceholder
                              size={"extra-small"}
                              type={"purple"}
                              label={getInitialsFromFullName(
                                time?.businessContact?.companyName || "",
                              )}
                            />
                            <div className={"pl-2 text-[14px] normal-case"}>
                              <Link
                                to={
                                  time.businessContact
                                    ? `/organization/${time.businessContactId}`
                                    : ""
                                }
                                className={
                                  "link-primary text-sm font-medium hover:underline"
                                }>
                                {time.businessContact
                                  ? time.businessContact.companyName
                                  : "Contact name"}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      {time.work?.service?.name}
                    </td>
                    <td
                      className={
                        "link-primary cursor-pointer px-[16px] py-[8px] align-top text-sm font-medium hover:underline"
                      }
                      onClick={() => {
                        setCurrentTime(time);
                        setIsOpenEdit(true);
                      }}>
                      {timeFormatter(time.duration)}
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      {moment(time.date).format("YYYY-MM-DD")}
                    </td>
                    <td
                      className={
                        "vertical px-[16px] py-[8px] align-top text-[14px]"
                      }>
                      <div className={"flex items-center"}>
                        <AvatarPlaceholder
                          size={"extra-small"}
                          label={getInitials(
                            time.assignedUser.userProfile.firstName || "",
                            time.assignedUser.userProfile.lastName || "",
                          )}
                        />
                        <div className={"pl-2 text-[14px] normal-case"}>
                          {time.assignedUser.userProfile
                            ? `${time.assignedUser.userProfile.firstName} ${time.assignedUser.userProfile.lastName}`
                            : "User name"}
                        </div>
                      </div>
                    </td>
                    <td
                      className={"px-[16px] py-[8px] text-[14px] "}
                      onClick={() => {
                        setCollapsedItems((prev) =>
                          collapsedItems.includes(time?.id)
                            ? prev.filter((item) => item !== time?.id)
                            : [...prev, time?.id],
                        );
                      }}>
                      <div
                        className={`${
                          !collapsedItems.includes(time?.id)
                            ? "...  max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] 2xl:max-w-[200px]"
                            : " max-w-[100px] whitespace-pre-line"
                        }`}>
                        {time.notes}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] text-[14px] "}>
                      {Math.trunc(
                        time.assignedUser.hourlyRate *
                          totalRate(timeFormatter(time.duration)),
                      )}
                      $
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </FormSection>
      {isOpenEdit && currentTime && (
        <EditTime
          closeModal={() => setIsOpenEdit(false)}
          time={currentTime}
          isUpdate={Math.random()}
        />
      )}
    </>
  );
};
