import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { reactPlugin } from "../../app/insights/AppInsightsPlugin";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { ErrorFallback } from "../../components/errors/ErrorFallback";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  getLinkedFirms,
  getUser,
  setIsLinkedFirm,
} from "../../services/AppService";
import {
  getLinkedFirm,
  setLinkedFirm,
} from "../../sessionStorage/sessionStorage";
import { MainSidebarNav } from "./MainSidebarNav";
import { MainTopNav } from "./MainTopNav";

const MainViewPages = ({ locationId }: { locationId?: string }) => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const dispatch = useAppDispatch();
  const { user, linkedFirms, isLinkedFirm } = useAppSelector(
    (state) => state.appReducer,
  );

  const [selectedFirm, setSelectedFirm] = useState(linkedFirms?.[0]?.id || "");
  const [error, setError] = useState("");

  useEffect(() => {
    if (linkedFirms?.length > 0) {
      if (linkedFirms?.length === 1) {
        handleClickGo(linkedFirms?.[0]?.id);
      } else {
        setSelectedFirm(linkedFirms?.[0]?.id);
      }
    }
  }, [linkedFirms]);

  useLayoutEffect(() => {
    if (userInfoByEmail?.id) {
      dispatch(
        getUser({
          userId: `${userInfoByEmail?.id}`,
          orgId: `${userInfoByEmail?.org?.id}`,
        }),
      );
    }
  }, [userInfoByEmail?.id]);

  useEffect(() => {
    if (user) {
      dispatch(setIsLinkedFirm(Boolean(getLinkedFirm())));
    }
  }, [user]);

  useEffect(() => {
    if (user?.id && !isLinkedFirm) {
      dispatch(getLinkedFirms(`${user?.id}`));
    }
  }, [user, isLinkedFirm]);

  const handleClickGo = async (firm: string) => {
    if (firm) {
      const response = await fetch(
        `${devConfig.getServerBaseUrlForEnv()}/api/user-organization/add-link`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "POST",
          body: JSON.stringify({
            userId: `${user?.id}`,
            orgId: `${firm}`,
          }),
        },
      );
      if (response.ok) {
        const data = await response.json();
        dispatch(
          getUser({
            userId: `${user?.id}`,
            orgId: user?.orgId,
          }),
        );
        window.location.reload();
        setLinkedFirm(data);
        dispatch(setIsLinkedFirm(true));
        setError("");
      } else {
        setError("Something went wrong");
        dispatch(setIsLinkedFirm(false));
      }
    }
  };

  const renderLinkedFirms = () => {
    if (linkedFirms?.length < 2) {
      return <></>;
    }

    return (
      <div>
        <div className={"flex h-screen w-screen items-center justify-center"}>
          <div
            className={
              "w-[480px] rounded-[35px] px-[60px] py-[30px] shadow-card"
            }>
            <div
              className={
                "text-purple w-full text-center text-[25px] font-bold"
              }>
              Procharted
            </div>
            <div className={"my-6 w-full text-center text-[20px] font-bold"}>
              Choose a firm
            </div>
            <div className={"mb-4"}>
              <select
                value={selectedFirm}
                onChange={(e) => setSelectedFirm(e.target.value)}
                className={"select select-bordered w-full"}>
                {linkedFirms?.map((firm) => (
                  <option value={firm?.id} key={firm?.id}>
                    {firm?.legalName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <Button
                label={"Go"}
                disabled={!selectedFirm}
                size={"small"}
                colorType={"secondary"}
                onClick={() => handleClickGo(selectedFirm)}
                extraClasses={"normal-case px-6"}
              />
              {error && (
                <div className={"mt-2 text-sm text-red-500"}>{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderApp = () => {
    return (
      <div className={"flex flex-col justify-start"}>
        <MainTopNav />
        <div
          className={
            "flex h-screen flex-grow justify-center bg-gray-100 pt-16"
          }>
          {/* side-nav sets the location */}
          <MainSidebarNav />

          {/* render content only if location id is not empty */}
          {!!locationId && (
            <div
              className={"h-full w-full min-w-[75vw] overflow-y-auto px-8"}
              id={"proc-main-view"}>
              <div className={"w-full py-4"}>
                <AppInsightsErrorBoundary
                  onError={ErrorFallback}
                  appInsights={reactPlugin}>
                  {/* render main route */}
                  <Outlet />
                </AppInsightsErrorBoundary>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isLinkedFirm) {
      return renderApp();
    } else {
      return renderLinkedFirms();
    }
  };

  return renderContent();
};

export default MainViewPages;
