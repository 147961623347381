import React from "react";
import {
  Link,
  LinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import classNames from "classnames";

type Props = {
  workPage?: boolean;
} & LinkProps;
export const CustomLink: React.FC<Props> = ({
  workPage,
  children,
  to,
  ...props
}) => {
  const resolved = useResolvedPath(to);
  const location = useLocation();
  const linkPath = resolved.pathname.split("/").filter((item) => item !== "");
  const locationPath = location.pathname
    .split("/")
    .filter((item) => item !== "");

  const match = linkPath.every((element) => locationPath.includes(element));

  const tabBaseClass = "tab tab-bordered font-notoSans !text-[16px]";
  const currentTabClass = match
    ? workPage
      ? "border-[#7C66F0] text-[#7C66F0] border-b-[2px]"
      : "tab-active font-notoSans !text-[16px]"
    : "";

  return (
    <div>
      <Link
        className={classNames(
          workPage
            ? "mr-[32px] py-2 text-[14px] font-semibold text-[#2B333CCC] hover:text-[#7C66F0]"
            : tabBaseClass,
          currentTabClass,
        )}
        to={to}
        {...props}>
        {children}
      </Link>
    </div>
  );
};

interface ICustomPageProps {
  onClick: () => void;
  extraClasses?: string;
  isActive: boolean;
}

export const CustomPage: React.FC<ICustomPageProps> = ({
  onClick,
  isActive,
  extraClasses,
  children,
  ...props
}) => {
  const tabBaseClass = "tab tab-bordered text-[16px]";
  const currentTabClass = isActive ? "tab-active text-[16px]" : "";

  return (
    <div
      onClick={onClick}
      className={`${tabBaseClass} ${currentTabClass} ${extraClasses}`}
      {...props}>
      {children}
    </div>
  );
};
