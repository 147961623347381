import moment from "moment/moment";
import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { StyledDatePicker } from "../../../../../../../../components/datepicker/datepicker";
import CustomSelect from "../../../../../../../../components/select/Select";
import { UpdateOrganizationModel } from "../../../../../../../../factories/contacts/models/update-organization.model";
import {
  useEditOrgContactMutation,
  useGetContactTypesQuery,
  useGetOrganizationTypesQuery,
} from "../../../../../../../../slices/ContactsSlice";
import {
  IOrganizationContact,
  salesTax,
  SelectOptionType,
} from "../../../../../../../../types/types";
import { getDaysOptions, MonthsEnum, monthsOptions } from "./options";

type Props = {
  organization: IOrganizationContact;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

type FormData = {
  companyName: string;
  businessNumber: string;
  yearEndMonth: SelectOptionType;
  yearEndDay: SelectOptionType;
  salesTaxId: SelectOptionType;
  organizationTypeId: SelectOptionType;
  phoneNumber: string;
  email: string;
  contactType: SelectOptionType;
  address: {
    line1: string;
    city: string;
    region: string;
    postalCode: string;
  };
  additionalInfo: string;
  incorporationDate: string;
};

export const OrganizationEditInfo: FC<Props> = ({
  organization,
  setIsEdit,
}) => {
  const { data: contactTypes, isLoading } = useGetContactTypesQuery();
  const { data: organizationTypes, isLoading: isLoadingOrganizationTypes } =
    useGetOrganizationTypesQuery();

  const [editOrganization, { isSuccess, isLoading: isLoadingEdit }] =
    useEditOrgContactMutation();

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      companyName: organization?.companyName || "",
      businessNumber: organization?.businessNumber || "",
      yearEndMonth: {
        value: organization?.yearEndMonth || "",
        label: organization?.yearEndMonth || "",
      },
      yearEndDay: {
        value: organization?.yearEndDay.toString() || "",
        label: organization?.yearEndDay.toString() || "",
      },
      salesTaxId: {
        value: organization?.salesTaxId || "",
        label: organization?.salesTaxType?.salesTaxType || "",
      },
      organizationTypeId: {
        value: organization?.organizationTypeId || "",
        label: organization?.organizationType?.organizationType || "",
      },
      phoneNumber: organization?.phoneNumber || "",
      email: organization?.email || "",
      contactType: {
        value: organization?.contactTypeEnumId || "",
        label: organization?.contactType?.contactType || "",
      },
      address: {
        line1: organization?.address?.line1 || "",
        city: organization?.address?.city || "",
        region: organization?.address?.region || "",
        postalCode: organization?.address?.postalCode || "",
      },
      additionalInfo: organization?.additionalInfo || "",
      incorporationDate: organization?.incorporationDate
        ? organization?.incorporationDate?.toString()
        : "",
    },
  });

  const salesTaxOptions: SelectOptionType[] = salesTax.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const onSubmit = (data: FormData) => {
    if (!isLoadingEdit) {
      const body: UpdateOrganizationModel = {
        id: organization?.id || "",
        companyName: data?.companyName || "",
        businessNumber: data.businessNumber || "",
        yearEndMonth: data.yearEndMonth.value as MonthsEnum,
        yearEndDay: +data.yearEndDay.value || 0,
        salesTaxId: data.salesTaxId.value || null,
        organizationTypeId: data.organizationTypeId.value || null,
        phoneNumber: data?.phoneNumber || "",
        email: data?.email || "",
        contactTypeEnumId: data.contactType.value,
        address: {
          line1: data.address.line1 || "",
          city: data.address.city || "",
          region: data.address.region || "",
          postalCode: data.address.postalCode || "",
        },
        incorporationDate: data?.incorporationDate || null,
        additionalInfo: data?.additionalInfo || "",
        socialIdentificationNumber: organization?.socialIdentificationNumber,
        contactStatusEnumId: organization?.contactStatusEnumId,
      };
      editOrganization(body);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
    }
  }, [isSuccess]);

  return (
    <Card extraClasses={"shadow-box"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <div className={"flex gap-2"}>
              <Button
                label={"Cancel"}
                buttonType={"button"}
                size={"custom"}
                colorType={"outline"}
                extraClasses={"btn-sm"}
                onClick={() => setIsEdit(false)}
              />
              <Button
                isLoading={isLoadingEdit}
                label={"Save"}
                disabled={isLoadingEdit}
                buttonType={"submit"}
                size={"custom"}
                extraClasses={"btn-sm"}
              />
            </div>
          </div>
          <div className={"grid-rows-[repeat(11, max-content)] grid gap-6"}>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>LEGAL NAME</div>
              <input
                {...register("companyName", {
                  required: "This field is required",
                  maxLength: {
                    value: 50,
                    message:
                      "Company name shouldn't be greater than 50 characters",
                  },
                })}
                type={"text"}
                placeholder={"Legal name"}
                className={"input input-bordered input-sm w-full"}
              />
              {errors?.companyName && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors?.companyName?.message}
                </div>
              )}
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                BUSINESS NUMBER
              </div>
              <input
                {...register("businessNumber")}
                type={"text"}
                placeholder={"Business number"}
                className={"input input-bordered input-sm w-full"}
              />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                INCORPORATION DATE
              </div>
              <Controller
                control={control}
                name={"incorporationDate"}
                render={({ field: { value, onChange } }) => (
                  <StyledDatePicker
                    value={moment(value || new Date()).toDate()}
                    onChange={(date) => onChange(moment(date).toISOString())}
                  />
                )}
              />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  YEAR END MONTH
                </div>
                <Controller
                  control={control}
                  name={"yearEndMonth"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={monthsOptions || []}
                      onChange={(newValue) => {
                        const newMonth = newValue as SelectOptionType;
                        onChange(newMonth);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  YEAR END DAY
                </div>
                <Controller
                  control={control}
                  name={"yearEndDay"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={
                        getDaysOptions(
                          watch("yearEndMonth")?.value as MonthsEnum,
                        ) || []
                      }
                      isLoading={isLoadingOrganizationTypes}
                      onChange={(newValue) => {
                        const organizationType = newValue as SelectOptionType;
                        onChange(organizationType);
                      }}
                      small
                    />
                  )}
                />
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>SALES TAX </div>
                <Controller
                  control={control}
                  name={"salesTaxId"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={salesTaxOptions}
                      onChange={(newValue) => {
                        const newSaleTax = newValue as SelectOptionType;
                        onChange(newSaleTax);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  ORGANIZATION TYPE
                </div>
                <Controller
                  control={control}
                  name={"organizationTypeId"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={organizationTypes || []}
                      isLoading={isLoadingOrganizationTypes}
                      onChange={(newValue) => {
                        const organizationType = newValue as SelectOptionType;
                        onChange(organizationType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>PHONE NUMBER</div>
              <input
                placeholder={"Phone number"}
                {...register("phoneNumber")}
                type={"text"}
                className={"input input-bordered input-sm w-full"}
              />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
              <input
                placeholder={"Email"}
                {...register("email", {
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                })}
                type={"text"}
                className={"input input-bordered input-sm w-full"}
              />
              {errors.email && (
                <span className="pt-2 text-sm font-bold text-error">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  CONTACT TYPE
                </div>
                <Controller
                  control={control}
                  name={"contactType"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={contactTypes || []}
                      isLoading={isLoading}
                      onChange={(newValue) => {
                        const contactType = newValue as SelectOptionType;
                        onChange(contactType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>ADDRESS</div>
              <div className={"grid grid-rows-[repeat(3,max-content)] gap-4"}>
                <input
                  placeholder={"Street address"}
                  {...register("address.line1")}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                <div className={"grid grid-cols-2 gap-4"}>
                  <input
                    placeholder={"City"}
                    {...register("address.city")}
                    type={"text"}
                    className={"input input-bordered input-sm w-full"}
                  />
                  <input
                    placeholder={"State / Province"}
                    {...register("address.region")}
                    type={"text"}
                    className={"input input-bordered input-sm w-full"}
                  />
                </div>
                <div className={"grid grid-cols-2 gap-4"}>
                  <input
                    placeholder={"Zip / Postal Code"}
                    {...register("address.postalCode")}
                    type={"text"}
                    className={"input input-bordered input-sm w-full"}
                  />
                  <div />
                </div>
              </div>
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                ADDITIONAL INFO
              </div>
              <textarea
                {...register("additionalInfo")}
                className={"input input-bordered min-h-[100px] w-full"}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};
