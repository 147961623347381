import { NotificationType } from "../factories/notifications/notification-type.enum";

export const repeats = [
  { id: "85a935e1-b716-4363-aaf1-582d1fe3bf8a", name: "Monthly" },
  { id: "51e84c88-3fbc-4b30-812b-115c0eeefc26", name: "Yearly" },
  { id: "2c8923ec-2278-4ecf-ad0d-9220b70aafbf", name: "Weekly" },
];
export const repeatNever = {
  id: "3EC19AAF-98DD-4018-ADA2-B033E5EC5C3C",
  name: "Never",
};
export const repeatTypeEnumNever = {
  id: "ea4bc051-000c-4990-9688-dce840be1daa",
  name: "Never",
};

export const repeatsOn = [
  { id: "3ec19aaf-98dd-4018-ada2-B033E5EC5C3C", name: "Never" },
  { id: "B13648F7-0739-4CA7-BF81-AB26F140F61D", name: "On Completion" },
  { id: "9C1ED469-B782-44B4-9E39-A0E74A232E1B", name: "Schedule" },
];

export const salesTax = [
  { id: "336C4959-2F73-4592-BC97-189E0A214B41", name: "Yearly" },
  { id: "072B39AB-D568-4B79-8DF6-60E1BA8BB508", name: "Monthly" },
  { id: "D728D90E-4257-4834-AB03-45B4D93E7DB2", name: "Quarterly" },
];

export interface LocationAddress {
  line1: string;
  line2: string;
  city: string;
  region: string;
  country: string;
  postalCode: string;
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface Location {
  orgId: string;
  locationId: string | null;
  locationAddress: LocationAddress;
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface IFirm {
  legalName: string;
  displayName: string;
  phoneNumbers: { number: string }[];
  locations: Location[];
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface SelectOptionType {
  label: string;
  value: string;
  secondValue?: string;
  avatar?: string;
  avatarColor?: "purple" | "blue";
  color?: string;
  isBold?: boolean;
  caption?: string;
  totalCount?: number;
  contactId?: string;
  businessContactId?: string;
}

export interface TFolder {
  id: string;
  name: string;
  attributes: string;
  grant_id: string;
  system_folder: boolean;
  total_count: number;
  unread_count: number;
}

export interface ITime {
  duration: number;
  notes: string;
  id: string;
  assignedUser: IUser;
  assignedUserId: string;
  work: IWork;
  workId: string;
  date: Date;
  contact: IContact | null;
  businessContact: IOrganizationContact | null;
  contactId: string | null;
  businessContactId: string | null;
  assignedUserRelayId: string | null;
}
export interface ISubStatusCount {
  name: string;
  subs: { name: string; count: number }[];
}
export interface ITimer {
  isRunning: boolean;
  isPause: boolean;
}
export interface IDashboard {
  completedWorkCount: number;
  openWorkCount: number;
  overDueWorkCount: number;
  assignedUserOpenWorkCount: { name: string; count: number }[];
  assignedUserOverDueCount: { name: string; count: number }[];
  workTypeOpenWorkCount: { name: string; count: number }[];
  workTypeOverDueCount: { name: string; count: number }[];
  statusCount: {
    "In Progress": number;
    Ready: number;
    Waiting: number;
  };
}
export interface addIndividualState {
  customerType: {
    id: string;
  };
}
export interface IPhoneType {
  id: string;
  phoneNumberType: string;
}
export interface IUserInvitation {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}
export interface IOrganizationContactNote {
  businessContactId: string;
  createdAt: Date;
  id: string;
  note: string;
  title: string;
  isPinned: boolean;
  createdByUser: { userProfile: { firstName: string; lastName: string } };
}
export interface IWorkTask {
  createdAt: string;
  description: string;
  id: string;
  isDone: boolean;
  jobId: string;
  jobIdBase64: string;
  name: string;
  order: number;
  assignedUserId: string;
  assignedUser: {
    id: string;
    userProfile: { firstName: string; middleName: string; lastName: string };
  };
}
export interface IWorkTemplateTask {
  name: string;
  description: string;
  linkName: null;
  linkUrl: null;
  order: number;
  isDone: boolean;
  workTemplateId: string;
  id: string;
  relayId: null;
  createdAt: Date;
  assignedUserId: string;
  assignedUser: {
    id: string;
    userProfile: { firstName: string; middleName: string; lastName: string };
  };
}
export interface ITemplate {
  templateName: string;
  description: string;
  orgId: string;
  workTemplateTasks: IWorkTemplateTask[];
  id: string;
  relayId: null;
  createdAt: Date;
}
/*serviceStatuses:*/
export interface IServiceStatusesWork {
  name: string;
  description: string;
  color: string;
  order: number;
  serviceId: string;
  workTypeStatusId: string;
  workTypeStatus: {
    name: string;
    description: string;
    color: string;
    order: number;
    workTypeSubStatuses: any[];
    id: string;
    relayId: null;
    createdAt: string;
  };
  serviceSubStatuses: [];
  countWork: number;
  id: string;
  relayId: null;
  createdAt: string;
}

export interface IService {
  name: string;
  description: string;

  serviceFee: null;
  id: string;
  relayId: null;
  createdAt: string;
  serviceStatuses: IServiceStatusesWork[];
}
export interface IWork {
  customerId: string;
  isReassigned: boolean;
  totalRecords: number;
  taskCountsByUser: number;
  jobTaskCounts: number;
  createdAt: string;
  id: string;
  name: string;
  assignedUser: {
    id: string;
    userProfile: { firstName: string; middleName: string; lastName: string };
  };
  assignedUserId: string;
  jobStatusId: string;
  jobSubStatusId: string;
  customer: { name: string; contactId: string; businessContactId: string };
  jobStatus: {
    name: string;
    color: string;
    id: string;
    workTypeStatusId: string;
  };
  jobSubStatus: {
    subStatus: string;
    color: string;
    serviceStatusId: string;
    worktypeSubStatusId: string;
  };
  repeatTypeEnum: { id: string; repeatType: string };
  repeatTypeRecurring: number;
  service: IService;
  serviceId: string;
  startDate: string;
  dueDate: Date;
  template: ITemplate;
  templateId: string;
  workTasks: IWorkTask[];
  businessContact: any;
  businessContactId: string;
  repeatValue?: number;
}

export interface IContactProfile {
  address: { line1: string; city: string; postalCode: string; region: string };
  contactId: string;
  customerRelayId: string;
  contactStatusTypeEnumId: string;
  contactType: string;
  contactTypeEnumId: string;
  customerId: string;
  customerType: string;
  customerTypeEnumId: string;
  email: string;
  firstName: string;
  lastName: string;
  locationId: string;
  middleName: string;
  orgId: string;
  phoneNumber: string;
  phoneNumberTypeEnumId: string;
  socialIdentificationNumber: string;
  additionalInfo: string;
  imageURL: string;
  id: string;
}

export interface IRepeatTypeEnum {
  createdAt: string;
  id: string;
  relayId: string;
  repeatType: string;
}

export interface ITaskTemplate {
  createdAt: string;
  id: string;
  org: string;
  orgId: string;
  orgRelayId: string;
  templateName: string;
  workTemplateTasks: IWorkTemplateTasks[];
  description: string;
}
export interface IWorkTemplateTasks {
  createdAt: string;
  description: string;
  id: string;
  isDone: boolean;
  linkName: string;
  linkUrl: string;
  name: string;
  order: number;
  relayId: string;
  workTemplateId: string;
}

export interface ISubStatus {
  id: string;
  subStatus: string;
  workTypeStatusId: string;
  check: boolean;
}

export interface IWorkflow {
  color: string;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  order: number;
  relayId: string;
  serviceId: string;
  workTypeSubStatuses: ISubStatus[];
}

export interface IWorkType {
  id: string;
  name: string;
  serviceStatuses: IServiceStatuses[];
}

export interface IServiceStatuses {
  id: string;
  name: string;
  workTypeStatusId: string;
  serviceSubStatuses: IServiceSubStatuses[];
}

export interface IServiceSubStatuses {
  id: string;
  serviceStatusId: string;
  subStatus: string;
  worktypeSubStatusId: string;
}

export interface IContact {
  contactStatusEnumId: string;
  contactTypeEnumId: string;
  createdAt: string;
  firstName: string;
  middleName: string;
  lastName: string;
  contactType: IContactType;
  customers: ICustomers[];
  email: IEmail;
  phoneNumbers: IPhoneNumber[];
  id: string;
  socialIdentificationNumber: string;
  address: string;
  customerType: string;
  phoneNumber: string;
  statusType: IStatusTypeContact;
  openWorkCount: number;
  customerId: string;
}

export interface IStatusTypeContact {
  createdAt: string;
  id: string;
  relayId: string;
  statusType: string;
}

export interface ICustomers {
  contactId: string;
  createdAt: string;
  customerTypeEnumId: string;
  id: string;
  name: string;
  relayId: string;
}

export interface ILinkedProfile {
  id: string;
  contactId: string;
  linkedContactId: string;
  relation: string;
  linkedProfile: ILinkedProfileContact;
  linkedCustomer: ILinkedProfileCustomer;
  linkedCustomerId: string;
  contact: IContact;
}

export interface ILinkedProfileOrgContact {
  id: string;
  contactId: string;
  linkedContactId: string;
  relation: string;
  linkedContact: ILinkedProfileContact;
  linkedCustomer: ILinkedProfileCustomer;
  linkedCustomerId: string;
  contact: IContact;
}

export interface ILinkedProfileContact {
  contactStatusEnumId: string;
  contactType: string;
  firstName: string;
  id: string;
  middleName: string;
  lastName: string;
  customers: { id: string }[];
}
export interface ILinkedProfileCustomer {
  businessContactId: string;
  contactId: string;
  createdAt: string;
  customerTypeEnumId: string;
  id: string;
  name: string;
}

export interface IContactType {
  contactType: string;
  contactTypeEnumId: string;
  createdAt: string;
  id: string;
}

export interface IOrganizationType {
  createdAt: string;
  id: string;
  organizationType: string | "";
}

export interface IEmail {
  emailAddress: string;
  contactId: string;
  createdAt: string;
  id: string;
}

export interface IPhoneNumber {
  contactId: string;
  createdAt: string;
  id: string;
  number: string;
  phoneNumberTypeEnumId: string;
}

export interface IUser {
  azureB2CUserId: string;
  createdAt: string;
  id: string;
  invitationCount: number;
  invitedAt: string;
  orgId: string;
  userRoleEnumId: string;
  org: IOrg;
  userAgreement: IUserAgreement;
  userFeatureFlag: IUserFeatureFlag;
  userProfile: IUserProfile;
  userRoleEnum: IUserRoleEnum;
  nylasToken: NToken;
  receiveEmailNotification: boolean;
  syncEmails: boolean;
  sendNotificationToEmail: string;
  permissionIds: string[];
  userPermission: IUserPermissions[];
  assignedCustomerIds: string[];
  assignedAllClients: boolean;
  hourlyRate: number;
}

export interface IUserPermissions {
  permissionId: string;
  permission: IPermissions;
  userId: string;
  id: string;
  relayId: string | null;
  createdAt: string;
  createdTimeUTC: string | null;
  editedTimeUTC: string | null;
}
export interface IPermissions {
  createdAt: string;
  createdTimeUTC: string | null;
  description: string | null;
  editedTimeUTC: string | null;
  id: string;
  permission: string;
  relayId: string | null;
}

interface JobTask {
  name: string;
  description: string;
  linkName: string | null;
  linkUrl: string | null;
  order: number;
  isDone: boolean;
  jobId: string;
  jobIdBase64: string | null;
  templateId: string | null;
  assignedUserId: string;
  assignedByUserId: string | null;
  assignedUser: string | null;
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface INotification {
  message: string;
  isRead: boolean;
  jobId: string | null;
  job: { name: string } | null;
  userId: string;
  user: IUser;
  mentionByUser: IUser;
  customerId: string | null;
  customer: { name: string } | null;
  jobTaskId: string;
  jobTask: JobTask;
  id: string;
  relayId: string | null;
  createdAt: string;
  notificationType: NotificationType;
}

export interface IOrg {
  id: string;
  legalName: string;
  locations: { id: string }[];
}

export interface IUserAgreement {
  acceptedPrivacyAt: string;
  acceptedTermsAt: string;
  userId: string;
}

export interface IUserFeatureFlag {
  id: string;
  quickStartV1Completed: string;
  userId: string;
}

export interface IUserProfile {
  createdAt: string;
  email: string;
  firstName: string;
  if: string;
  lastName: string;
  middleName: string;
  userId: string;
}

export interface NToken {
  userId: string;
  token: string;
  grantId: string;
  email: string;
}
export interface IUserRoleEnum {
  createdAt: string;
  id: string;
  relayId: string;
  userRole: string;
}

export interface IWorkNote {
  createdAt: Date;
  createdByUser: { userProfile: { firstName: string; lastName: string } };
  createdByUserId: string;
  id: string;
  isPinned: boolean;
  modifiedByUser: string | null;
  note: string;
  relayId: string;
  title: string;
  updatedAt: string;
  workId: string;
}
export interface IOrganizationContact {
  businessCustomers: { id: string }[];
  customerId: string;
  incorporationDate: Date;
  yearEnd: string;
  organizationType: IOrganizationType;
  businessNumber: string;
  phoneNumber: string;
  phoneNumbers: { number: string }[];
  companyName: string;
  contact: IContact;
  contactId: string;
  contactStatusEnumId: string;
  contactType: IContactType;
  contactTypeEnumId: string;
  createdAt: string;
  id: string;
  locationId: string;
  relayId: string;
  role: string;
  socialIdentificationNumber: string;
  email: string;
  address: { line1: string; city: string; region: string; postalCode: string };
  statusType: IStatusTypeContact;
  openWorkCount: number;
  additionalInfo: string;
  organizationTypeId: string;
  salesTaxId: string;
  salesTaxType: { salesTaxType: string; id: string };
  yearEndMonth: string;
  yearEndDay: number;
  imageURL: string;
}
