import React, { FC, useState } from "react";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { Link } from "react-router-dom";
import { timeFormatter } from "../../../constants/constants";
import moment from "moment/moment";
import { IWork, SelectOptionType } from "../../../types/types";
import Button from "../../../components/button/Button";

type Props = {
  isLoading: boolean;
  collapsedItems: string[];
  setCollapsedItems: React.Dispatch<React.SetStateAction<string[]>>;
  currentContact: SelectOptionType | null;
  works: IWork[] | undefined;
  billedWorks: IWork[];
  setBilledWorks: React.Dispatch<React.SetStateAction<IWork[]>>;
  onClose: () => void;
  isActive: boolean;
};
export const BillableTime: FC<Props> = ({
  isLoading,
  currentContact,
  collapsedItems,
  setCollapsedItems,
  works,
  billedWorks,
  setBilledWorks,
  onClose,
  isActive,
}) => {
  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };
  const [billedItem, setBilledItem] = useState<IWork[]>(billedWorks);
  console.log(billedItem);
  console.log(billedWorks);
  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-[80%] max-w-[90%]"}>
        {isLoading ? (
          <LottieLoading />
        ) : (
          currentContact &&
          works && (
            <table className={"w-full"}>
              <thead className={"border-b-[1px] border-gray-300"}>
                <tr className={"py-[16px] pl-[16px] pr-[32px] last:pr-[16px]"}>
                  <th
                    className={
                      "p-4 text-start text-sm normal-case text-gray-400"
                    }>
                    <input
                      className={
                        "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                      }
                      onChange={(e) =>
                        e.target.checked
                          ? setBilledItem(works || [])
                          : setBilledItem([])
                      }
                      checked={(works?.length || 0) === billedItem.length}
                      type="checkbox"
                    />
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Work
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Work Type
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Total Time
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Date
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Assignee
                  </th>
                  <th
                    className={
                      "w-[140px] py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Notes
                  </th>
                  <th
                    className={
                      "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] last:pr-[16px]"
                    }>
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                {works?.map((work: any) => (
                  <tr
                    key={work?.id}
                    className={
                      "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                    }>
                    <td className={"w-[30px] pl-4"}>
                      <input
                        className={
                          "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
                        }
                        onChange={(e) =>
                          !e.target.checked
                            ? setBilledItem((prev) =>
                                prev.filter((i) => i.id !== work.id),
                              )
                            : setBilledItem((prev) => [...prev, work])
                        }
                        type="checkbox"
                        checked={billedItem.some((i) => i.id === work.id)}
                      />
                    </td>
                    <td className={"px-[16px] py-[8px] align-top"}>
                      <div className={"flex items-center"}>
                        <div className={"max-w-[300px] whitespace-normal"}>
                          <Link
                            to={`/work/${work?.work?.id}`}
                            className={
                              "link-primary text-sm font-medium hover:underline"
                            }>
                            {work.work.name}
                          </Link>
                        </div>
                      </div>
                    </td>

                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      {work.work.service?.name}
                    </td>
                    <td
                      className={
                        "link-primary cursor-pointer px-[16px] py-[8px] align-top text-sm font-medium hover:underline"
                      }>
                      {timeFormatter(work.duration)}
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      {moment(work.date).format("YYYY-MM-DD")}
                    </td>
                    <td className={"px-[16px] py-[8px] align-top text-[14px]"}>
                      {work.assignedUser.userProfile.firstName +
                        " " +
                        work.assignedUser.userProfile.lastName}
                    </td>
                    <td
                      className={"px-[16px] py-[8px] text-[14px] "}
                      onClick={() => {
                        setCollapsedItems((prev) =>
                          collapsedItems.includes(work?.id)
                            ? prev.filter((item) => item !== work?.id)
                            : [...prev, work?.id],
                        );
                      }}>
                      <div
                        className={`${
                          !collapsedItems.includes(work?.id)
                            ? "...  max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] 2xl:max-w-[200px]"
                            : " max-w-[100px] whitespace-pre-line"
                        }`}>
                        {work.notes}
                      </div>
                    </td>
                    <td className={"px-[16px] py-[8px] text-[14px] "}>
                      {Math.trunc(
                        work.assignedUser.hourlyRate *
                          totalRate(timeFormatter(work.duration)),
                      )}
                      $
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}
        <div className={"mt-3 flex w-full justify-end gap-4 px-5"}>
          <Button
            label={"Add"}
            onClick={() => {
              setBilledWorks(billedItem);
              onClose();
            }}
          />
          <Button
            label={"Cancel"}
            colorType={"outline"}
            onClick={() => {
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};
